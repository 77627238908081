.ana-per{
    display: flex;
    gap: 2rem;
}
.ana-pers{
    display: flex;
    flex-direction: column;
    gap:  .5rem;
}
.ana-test{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    border-top: 1px solid gray;
    margin-bottom: 2rem;
    padding-top: 1rem;

}
.ana-t{
    flex: 1;
    border-right: 1px solid gray;
    padding-right: .5rem;
}
.ana-test :last-child{
    border-right: none;
}
.ana-t h1{
    margin-bottom: 2rem
}
.ana-ques{
    display: flex;
flex-wrap:wrap;
    gap: .5rem;
    margin-top: 1rem;
}
.ana-ques label{
    font-weight: lighter;
    display: flex;
    gap: 1rem;
}

.ana-input{
    height: 2rem;
    width: 6rem !important;
    border-right: 2px solid black !important;
   
}
.ana-hairscalp{
    border-top: 1px solid gray;
    padding-top: 1rem;
    margin-bottom: 2rem;
}
.ana-hairscalp h2{
    margin-top: 1rem;
}


/* src/Test3.module.css */
.formContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .sectionTitle {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .checkboxGroup label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 1em;
    color: #555;
  }
  
  .checkboxGroup input {
    margin-right: 10px;
  }
  .label4{
    font-weight: 400;
  }

  /* ///////////////////////////////// */

  .doc-testp label{
font-size: 1rem;
padding: .4rem;
  }
  .doc-testp h4{
    font-size: 1rem !important;
  }
  .optionss{
    font-size: 1rem;
    display: flex;
    gap: .5rem ;
  }
  .sub-optionss div{
    margin-left:1.5rem;
   
  }
  .formss p{
    margin: 1rem 0;
  }
  .concass{
    margin-top: 3rem;
  }
  .sub-optionss h4{
    font-weight: 400;
    margin-top: 0 !important;
  }
  .div-check{
    display: flex;
    gap: 1rem;
  }
  .div-check input{
    margin-top: .7rem;
    width: 2rem;
    height: 2rem;
  }

  /* ////////////////////////// */

  /* App.css */

.custom-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
  /* height: 100vh; */
}
.diva1{
  margin-top: 1rem;
}
.btn-tt{
  margin-top: 2rem;
}
.custom-side-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-left-panel, .custom-right-panel {
  flex: 1;
}

.custom-side-image {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}

.custom-color-buttons {
  display: flex;
  gap:.4rem;
  align-items: center;
  margin-top: 2px;
}
.color-diva{
  display: flex;
  height: 3.5rem;
  align-items: center;
  gap:.5rem;
  background-color: #ccc;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding:.2rem;
}
.green{
 background-color: green;
}
.yellow{
 background-color: yellow;

}
.color-diva img{
  border-radius: 50%;
}
.custom-color-btn {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
}

.custom-green-btn {
  background-color: #72e972;
}


.custom-yellow-btn {
  background-color: #eeee68;
}

.custom-center-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-color-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  /* background-color: #ccc; */

}

.custom-color-circle-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 220px;
  height: 220px; */
  border-radius: 50%;
  /* background-color: #ccc; */

}
.diag1{
  margin-bottom: 0 !important;
  padding: 1rem;
  background-color: #5f8aff !important;
}

.checkbox-container11 input[type="checkbox"] {
  cursor: pointer;
}

.main-circle-health{
  padding : 70px
}

.color-diva-1{
  display: flex;
  height: 3.5rem;
  align-items: center;
  gap:.5rem;
  background-color: #ccc;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding:.2rem;
}

@media (max-width: 768px) {
  .main-circle-health{
    padding : 20%
  }
  .color-diva-1{
    height: 100%;
  }
}