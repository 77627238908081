.report-container {
    /* width: 793.7px; */
    /* height: 1122.5px; */
    margin: 0 auto;
    align-items: center;
    padding: 20px;
    border:1px solid gray
}

.heading-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid black;
}

.image-container {
    flex: 0 0 auto;
    width: 65%;
}

.rx-logo {
    max-width: 18%;
    display: block;
}

.logo-main {
    max-width: 30%;
}

.patient-detail-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.patient-detail-container h3 {
    font-weight: 500;
}

.time-detail span {
    font-weight: 600;
}

.phone-detail {
    margin-top: 20px;
}

.phone-detail span {
    font-weight: 600;
}

.doctor-notes-container {
    margin-top: 15px;
}

.doctor-notes-container h2 {
    text-decoration: underline;
}

.medicine-container {
    display: flex;
}

.labs {
    flex: 0 0 auto;
    width: 30%;
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    /* height: 100%; */
}

.medicine {
    flex: 0 0 auto;
    width: 70%;
    border-top: 3px solid #000;
}

.med-heading {
    border-bottom: 3px solid #000;
}

.input-med {
    display: flex;
    gap: 10px;
}

.sub-input-med {
    margin-left: 20px;
}

.presc {
    padding: 10px;
}

.presc p {
    text-decoration: underline;
}

.item2559 {
    border: none;
}

.dec-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.disclaimer {
    margin-top: 8px;
    
}

.disclaimer div {
    font-size: 0.7rem !important;
}
.img-sign{
width:160px;
}
.pdf{
    width: 500px;
    border: none;
    background-color: green;
    padding: 1rem;
    margin: 1rem 0;
    color: white;
}
.pdf :hover{
    cursor: pointer;
}
.color-head-blue{
    color: #008CD7
}
@media print {
    .report-container {
        page-break-inside: avoid;
    }
}


.pdf-1{
    width: 100px;
    border: none;
    background-color: green;
    padding: 1rem;
    margin: 1rem 0;
    color: white;
}
.pdf-1 :hover{
    cursor: pointer;
}