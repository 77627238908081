

.nutrition {
  top: 2px;
  left: 0px;
  color: rgba(40, 40, 40, 1);
  right: 0px;
  height: auto;
  margin: auto;
  position: relative;
  font-size: 35px;
  font-style: Bold;
  text-align: center;
  font-family: DM Sans;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.header-text{
  margin-top: 2%;
}
.smart-report {
  margin-left: -10em;
  color: rgba(84, 84, 84, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: center;
  font-family: DM Sans;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.foodhair-box{
  display: flex;
  justify-content: center;
}

.foodcard-img{
    width: 50%;
    height: 900px;
    /* border-image-source: url("../../../../../public/assets/img/reports/management/page5/layer11232-qixw.svg"); */
    position: relative;

    text-align: left;
    /* margin: 100px auto; */

    display: flex;
    flex-direction: column;
}
.foodcard-img2{
  width: 50%;
  height: 900px;
  /* border-image-source: url("../../../../../public/assets/img/reports/management/page5/layer11232-qixw.svg"); */
  position: relative;

  text-align: left;
  /* margin: 100px auto; */
  display: flex;
  flex-direction: column;
}
.hairloss-img{
  display: flex;
  justify-content: center;
}
.hairloss-img1 {
 position: relative;
 height: 100%;
}
.food-subtxt{
  color: rgba(55, 55, 55, 1);
  height: auto;
  position: relative;
  font-size: 20px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 123.50000143051147%;
  font-stretch: normal;
  text-decoration: none;
}
.food-content {
  color: rgba(73, 72, 72, 1);
  margin: 0em 1.4em;
  width: 90%;
  height: auto;
  position: relative;
  font-size: 10px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 189.9999976158142%;
  font-stretch: normal;
  text-decoration: none;
}
.before-title {
  color: rgba(0, 0, 0, 1);
  height: auto;
  margin: auto;
  position: relative;
  font-size: 15px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 189.9999976158142%;
  font-stretch: normal;
  text-decoration: none;
  width: 60%;
}
.faq-titledesign{
  /* margin-top: 4em; */
  display: flex;
  justify-content: center;
}
.faq-box1 {
  width: 35.64px;
  height: 210px;
  position: relative;
  border: 4px solid #016A44;
  background-color: #016A44;
  transform: skew(-20deg);
}
.faq-box6 {
  margin-left: -2em;
  width: 35.64px;
  height: 210px;
  position: relative;
  border: 4px solid #016A44;
  background-color: #016A44;
  transform: skew(-20deg);
  z-index: 1;
}
.faq-box2 {
  margin-left: -2em;
  margin-top: 2.8em;
  display: flex;
  justify-content: center;
  gap: 8%;
  /* width: 1253px; */
  height: 150px;
  position: relative;
  border: 4px solid #016A44;
  transform: skew(-20deg);
  z-index: 999999 !important;
}
.faq-boxdesign{
  display: flex;
  gap: 2%;
}
.faq-box3 {
  top: 16px;
  left: 4.54000854492188px;
  width: 7.46px;
  height: 104px;
  position: relative;
  border: 4px solid #016A44;
  transform: skew(1deg);
}

.faq-box4 {
    top: 16px;
    left: 14.54000854492188px;
    width: 7.46px;
    height: 104px;
    position: relative;
    border: 4px solid #016A44;
    transform: skew(1deg);
}
.faq-title {
  margin-top: 1.6em;
  color: rgba(1, 106, 68, 1);
  width: 60%;
  height: auto;
  position: relative;
  font-size: 24px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: 123.50000143051147%;
  font-stretch: normal;
  text-decoration: none;
}
.faq-boxs{
    display: flex;
}
.faq-img{
  width: 47%;
  height: 800px;
  /* border-image-source: url("../../../../../public/assets/img/reports/management/page5/layer11232-qixw.svg"); */
  position: relative;

  text-align: left;
  /* margin: 100px auto; */
  display: flex;
  flex-direction: column;
}
.faq-content {
  color: rgba(73, 72, 72, 1);
  margin: 1.4em;
  width: 88%;
  height: auto;
  position: relative;
  font-size: 10px;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: 189.9999976158142%;
  font-stretch: normal;
  text-decoration: none;
}






.management-report-page5-nutrition-mngmnt2-text105 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text106 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text108 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text110 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text111 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text113 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text114 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text116 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text117 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text119 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text120 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text122 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text123 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text125 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text126 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text128 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text129 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text131 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text132 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text134 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text135 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text137 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text141 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text143 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text145 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text147 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text149 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text151 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text153 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text155 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text157 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text159 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text161 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text163 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text165 {
  font-weight: 700;
}

.management-report-page5-nutrition-mngmnt2-text172 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text174 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text175 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text177 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text178 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text180 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text181 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text183 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text185 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text186 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text188 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text189 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text191 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text192 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text194 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text195 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text197 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text198 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text200 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text201 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text203 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text204 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text206 {
  font-weight: 700;
}

.management-report-page5-nutrition-mngmnt2-text210 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text212 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text214 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text216 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text218 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text220 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text222 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text224 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text226 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text228 {
  font-weight: 700;
}
.management-report-page5-nutrition-mngmnt2-text230 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text232 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text234 {
  font-weight: 400;
}
.management-report-page5-nutrition-mngmnt2-text236 {
  font-weight: 700;
}



@media only screen and (min-width: 300px) and (max-width: 1499px) {
  .hairloss-img{
    padding: 2em 10em 1em 10em;
  }
  .food-content {
    width: 91.6%;
    margin: 1em 2em;
  }
  .food-subtxt{
    line-height: 2px;
  }
  .faq-titledesign{
    margin: auto;
    width: 80%;
  }
  .faq-box6{
    margin-left: 0.1em;
  }
  .faq-content {
    width: 88.6%;
    margin: 5em 2em;
  }
}
@media only screen and (min-width: 300px) and (max-width: 700px) {
  /* .foodhair-box{
    grid-template-columns: repeat(1,1fr);
  } */
  .faq-titledesign{
    margin: auto;
    width: 80%;
  }
  .faq-box6{
    margin-left: 0.1em;
  }
  /* .faq-boxs{
    display: grid;
    grid-template-columns: repeat(1,1fr);
  } */
}


.teijsie-2{
    background: url("../../../../../public/assets/img/reports/management/page4/pencil-border.png");
    background-size: contain;
    height: 667px;

    background-repeat: no-repeat;
    padding: 44px 40px 0 20px;
  }