.about-us {
  background: linear-gradient(
    135deg,
    rgb(142, 208, 235) 33%,
    rgb(249, 235, 206) 66%
  );
}

.about-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.about-section-2 {
  display: flex;
}
.item-2 {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-right: 1rem;
  flex: 0 0 auto;
  width: 50%;
}
.image-2 {
  padding-left: 1rem;
  flex: 0 0 auto;
  max-width: 50%;
}
.image-2 img {
  max-width: 100%;
}
.item-2 h1 {
  font-size: 2.5rem;
}
.h1-span {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
  color: #08baed;
}

.item-2 span {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}
.item-2 p {
  font-size: 1.2rem;
  line-height: 1.5;
}
.section-3-wrapper {
  background-image: linear-gradient(
    to top,
    #c0edff,
    #cbf0ff,
    #d5f3ff,
    #e0f6ff,
    #eaf9ff
  );
}
.content-3 {
  display: flex;
  /* padding: 1rem; */
}
.content-container {
  /* flex: 0 0 auto;
    width: 33.333333%; */
  flex: 0 0 auto;
  width: 33.333333%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.content-container img {
  height: 32rem;
}
.content-item {
  display: flex;
  flex: 0 0 auto;
  width: 50%;
  gap: 1rem;
  padding: 1rem;
}

.content-3-heading {
  text-align: center;
  margin-top: 2rem;
  padding: 3rem 0 1rem 0;
}

.content-3-heading::before,
.content-3-heading::after {
  content: "";
  display: inline-block;
  width: 36%; /* Adjust the width as needed */
  height: 2px; /* Adjust the height of the line */
  background-color: #08baed; /* Color of the line */
  vertical-align: middle;
  margin-right: 15px;
  margin-left: 15px;
}

.content-container-2 {
  display: flex;
  flex-wrap: wrap;
}
.content-item span {
  font-size: 2rem;
  font-weight: 600;
  color: #08baed;
}
.content-sub-item {
  margin-bottom: 1rem;
  font-weight: 600;
}
.about-container-3 {
  display: flex;
  margin-top: 5rem;
}
.container-3-item {
  flex: 0 0 auto;
  width: 50%;
}
.about-container-3 p {
  margin-top: 2rem;
  line-height: 1.7;
}

.contect-us-heading-2::before,
.contect-us-heading-2::after {
  content: "";
  display: inline-block;
  width: 16%; /* Adjust the width as needed */
  height: 2px; /* Adjust the height of the line */
  background-color: #08baed; /* Color of the line */
  vertical-align: middle;
  margin-right: 15px;
  margin-left: 15px;
}
.head-2-container {
  text-align: center;
  margin-top: 3rem;
}
.about-4-items {
  display: flex;
  margin-top: 3rem;
  gap: 2rem; /* Adjust the gap between items */
}

.items-con {
  flex: 1; /* Each div takes up an equal amount of space */
  background-image: linear-gradient(to bottom, transparent, #fdf4c9);

  /* Add padding to create some space inside each div */
}
.circle-4 {
  padding: 1.5rem;
  background-color: #fdf4c9;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}
.circle-conta {
  padding: 1.5rem;
  padding-top: 0;
  background-color: #fdf4c9 !important;
}
.about-4-items img {
  max-width: 100%;
}
.about-4-items button {
  border: none;
  padding: 0.5rem 1rem;
  background-color: white;
  color: black;
  width: 100%;

  border-radius: 32px;
  font-size: 1.2rem;
}

.about-4-items p {
  font-size: 13px;
  background-color: #fdf4c9;
  padding: 1.5rem;
  padding-top: 0;
}
.hero-btn-about {
  text-align: center;
  margin-top: 2rem;
}
.our-commitment-container {
  margin-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  color: white;
  background: url("../../../public/assets/img/about/commitment-bg.jpg");
  background-size: cover;
}
.our-commitment-container h1 {
  padding-top: 6rem;
  margin-bottom: 3rem;
}
.our-commitment-container p {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 3rem;
}
.commit-sub {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #08baed;
}
.commit-con {
  display: flex;
  gap: 2rem;
}
.commit-item {
  flex: 1;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.4);
}
.commit-item p {
  text-align: start;
  margin: 2rem 0;
}
@media screen and (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
  }
  .our-commitment-container {
    padding: 0.5rem;
  }
  .commit-con {
    display: block;
  }
  .about-us-container img {
    max-width: 100%;
  }
  .item-2,
  .image-2 {
    width: 100%;
    max-width: none;
    text-align: center;
    padding: 0;
  }
  .content-3 {
    flex-direction: column;
  }
  .item-2 h1 {
    font-size: 2rem;
  }

  .item-2 span {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .item-2 p {
    font-size: 1rem;
    line-height: 1.5;
  }
  .content-3-heading {
    padding: 0rem 0 1rem 0;
  }
  .content-container {
    width: 100%;
  }
  .about-section-2 {
    flex-direction: column;
    padding: 1rem;
  }
  .image-2 {
    padding: 0;
    margin-top: 2rem;
  }
  .content-container-2 {
    flex-direction: column;
  }
  .content-3-heading::before,
  .content-3-heading::after {
    width: 15%;
  }
  .content-item {
    width: 100%;
    padding: 1rem 0;
  }

  .content-container-2 .content-item:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .content-item span {
    font-size: 1.5rem;
  }

  .content-sub-item {
    margin-bottom: 0.5rem;
  }

  .about-container-3,
  .about-container-4 {
    flex-direction: column;
  }
  .about-container-3 {
    padding: 1rem;
    margin-top: 2rem;
  }

  .container-3-item {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  .container-3-item img {
    max-width: 100%;
  }
  .contect-us-heading-2 {
    font-size: 1.5rem;
  }
  .contect-us-heading-2::before,
  .contect-us-heading-2::after {
    width: 0;
  }
  .about-4-items {
    flex-direction: column;
    gap: 1rem;
  }

  .items-con {
    padding: 1rem;
    margin: 0;
    text-align: center;
    background-image: linear-gradient(to bottom, transparent, #fdf4c900);
  }

  .circle-4,
  .circle-conta {
    /* padding: 1rem; */
  }
  .circle-4 img {
    padding-top: 1.5rem;
  }
  .about-4-items button {
    font-size: 1rem;
  }

  .about-4-items p {
    font-size: 0.9rem;
  }
}
