 /* .overlay {
  z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center; 
    transition: all 0.3s ease 0s;

}
  .popup {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .login-form {
    width: 300px; 
    padding: 50px;
   
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
  }
  
  .login-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error {
    font-size: 14px;
    color: red;
    margin-top: 5px;
  }
  
  .submit {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit:hover {
    background-color: #0056b3;
  }
  
  .signup-message {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .signup-message a {
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-message a:hover {
    text-decoration: underline;
  }
  .close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    border: navajowhite;
    background: red;
    display: flex;
    border-radius: 3px;
  }
  .popup{
    position: relative;
  } 

  
 */
 .overlay-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* .popup-login {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
} */
 .popup-login {
      /* height: 520px; */
      width: 300px;
      background-color: rgba(255,255,255,0.13);
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      border: 2px solid rgba(255,255,255,0.1);
      box-shadow: 0 0 40px rgb(8 7 16 / 60%);
      padding: 50px 35px;
 }



.close-btn-login {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.forgot-password-btn{
  border: none;
  text-align: end;
  text-decoration: underline;
  background: white;
}
.login-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab2-login {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
}

.tab2-login.active {
  background-color: #007bff;
  color: white !important;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.login-label {
  font-weight: bold;
}

.login-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.submit {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.submit:hover {
  background-color: #0056b3;
}

.signup-message {
  margin-top: 15px;
  text-align: center;
}
/* //////////////////////////////////// */
.overlay-forgot {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-forgot {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-btn-forgot {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

.forgot-password-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.submit-forgot {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-forgot:hover {
  background-color: #0056b3;
}
/* ////////////////////////////////////// */
.overlay-update-password {
  position: fixed;
  top: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-update-password {
position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.update-password-form {
  width: 300px;
}

.update-password-form .form-group {
  margin-bottom: 15px;
}

.update-password-form label {
  display: block;
  margin-bottom: 5px;
}

.update-password-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-password-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-password-form button:hover {
  background-color: #0056b3;
}

.shape:first-child {
  background: linear-gradient( #1845ad, #23a2f6 );
  left: -80px;
  top: -80px;
}

.shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}