.trust-container{
    display: flex;
    justify-content: space-between;
    padding:1.5rem 0 1.5rem 0;
 
}
hr{
    max-width: 1280px;
    margin: 0 auto;
    border-top: 3px solid #fbd84e;
    opacity: 1;
    margin-top: 10px;
   
}
.trust{
    display: flex;
    gap:.8rem;
   
}
.trust-text{
    font-size: 1.3rem;
    font-weight: 600;
}

@media (max-width: 768px){
    .trust-container {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        padding: 1.5rem 0 1.5rem 0;
        margin-left: 2rem;
        gap: 1rem;
    }
}