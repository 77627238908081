.hair-analysis{
    background-image: url('../../../public/assets/img/home/analysis-bg.png');
    display: flex;

  
}
.ana{
    flex: 1;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.anakkk{
   
   text-align: center;
}
.ana h1{
    margin-bottom: 1rem;
}
.ana img{
    max-width: 100%;
}
@media (max-width: 768px) {
.hair-analysis{
    flex-direction: column;
    margin-bottom: 2rem;
    padding: .5rem;
}
}