.frame3-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .frame3-frame3 {
    width: 100%;
    height: 2247px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: rgba(248, 249, 251, 1);
  }
  .frame3-rectangle34624510 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 111px;
    position: absolute;
  }
  .frame3-frame1261153115 {
    gap: 72.53732299804688px;
    top: 172px;
    left: 0px;
    right: 0px;
    width: 839px;
    margin: auto;
    display: flex;
    padding: 36.26866149902344px 65.28358459472656px;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 2.417910575866699px;
    justify-content: center;
    background-color: rgba(190, 206, 55, 1);
  }
  .frame3-text10 {
    color: rgba(0, 0, 0, 1);
    width: 674px;
    height: auto;
    font-size: 40px;
    font-style: Bold;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text11 {
    top: 40px;
    left: 80%;
    color: rgba(84, 84, 84, 1);
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-rectangle34624511 {
    top: 2104px;
    left: 0px;
    width: 100%;
    height: 143px;
    position: absolute;
  }
  .frame3-text12 {
    top: 2110px;
    left: 0px;
    color: rgb(255, 255, 255);
    right: 0px;
    width: 1317px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 15px;
    font-style: Bold;
    text-align: center;
    font-family: Poppins;
    font-weight: 700;
    line-height: 146%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text13 {
    font-weight: 700;
  }
  .frame3-group1707485983 {
    top: 15px;
    left: 63px;
    width: 286px;
    height: 81.8226547241211px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame3-rectangle34624522 {
    top: 0px;
    left: 0px;
    width: 286px;
    height: 81px;
    position: absolute;
  }
  .frame3hairsn11 {
    top: 0px;
    left: 18.0625px;
    width: 249px;
    height: 82px;
    position: absolute;
  }
  .frame3-text15 {
    top: 30px;
    left: 40%;
    color: rgba(40, 40, 40, 1);
    height: auto;
    position: absolute;
    font-size: 40px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-image237 {
    top: 364px;
    left: 63px;
    width: 405px;
    height: 295px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
  }
  .frame3-text16 {
    top: 377px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 6%;
    width: 481px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text17 {
    top: 1023px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 6%;
    width: 506px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text18 {
    top: 1715px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 6%;
    width: 486px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-rectangle34624530 {
    top: 364px;
    height: 295px;
    position: absolute;
    border-radius: 11px;
    left: 70%;
    width: 368px;
  }
  .frame3image5962710 {
    top: 377px;
    left: 76%;
    width: 162px;
    height: 166px;
    position: absolute;
    border-radius: 236px;
  }
  .frame3-text19 {
    top: 558px;
    left: 71%;
    color: rgba(0, 0, 0, 1);
    width: 353px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text20 {
    top: 705px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 0px;
    width: 1077px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Medium;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text21 {
    top: 1418px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 0px;
    width: 1077px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Medium;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-text22 {
    top: 1990px;
    left: 0px;
    color: rgba(0, 0, 0, 1);
    right: 0px;
    width: 1077px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 20px;
    font-style: Medium;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-frame1261153144 {
    gap: 62.61194610595703px;
    top: 817px;
    left: 0px;
    right: 0px;
    width: 839px;
    margin: auto;
    display: flex;
    padding: 31.305973052978516px 56.350746154785156px;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 2.087064743041992px;
    justify-content: center;
    background-color: rgba(248, 180, 115, 1);
  }
  .frame3-text23 {
    color: rgba(0, 0, 0, 1);
    width: 424px;
    height: auto;
    font-size: 40px;
    align-self: center;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-image239 {
    top: 44%;
    left: 63px;
    width: 405px;
    height: 405px;
    position: absolute;
    border-radius: 14px;
  }
  .frame3-group1707485989 {
    top: 44%;
    height: 324px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
    background-color: rgba(248, 180, 115, 0.20000000298023224);
    left: 70%;
    width: 368px;
  }
  .frame3-group1707485988 {
    top: 47px;
    left: 23px;
    width: 342px;
    height: 230px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame3-text24 {
    top: 179px;
    color: rgba(0, 0, 0, 1);
    width: 342px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3image7479559 {
    top: 0px;
    left: 95px;
    width: 152px;
    height: 151px;
    position: absolute;
    border-radius: 1111px;
  }
  .frame3-rectangle34624531 {
    top: 1706px;
    height: 230px;
    position: absolute;
    border-radius: 11px;
    left: 70%;
    width: 368px;
  }
  .frame3-text25 {
    top: 1892px;
    left: 71%;
    color: rgba(0, 0, 0, 1);
    width: 342px;
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 139.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-frame1261153117 {
    gap: 62.61194610595703px;
    top: 1522px;
    left: 0px;
    right: 0px;
    width: 839px;
    margin: auto;
    display: flex;
    padding: 31.305973052978516px 56.350746154785156px;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 2.087064743041992px;
    justify-content: center;
    background-color: rgba(230, 146, 208, 1);
  }
  .frame3-text26 {
    color: rgba(0, 0, 0, 1);
    width: 328px;
    height: auto;
    font-size: 41.50246047973633px;
    align-self: center;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame3-stressometer11 {
    top: 1706px;
    left: 59px;
    width: 411px;
    height: 230px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame3image2395 {
    top: 1724px;
    left: 77%;
    width: 146px;
    height: 147px;
    position: absolute;
    border-radius: 74.5px;
  }
  .frame3-frame1261153145 {
    top: 178px;
    left: 90px;
    width: 100px;
    height: 100px;
    display: flex;
    overflow: hidden;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1488px) {
    .frame3-text11 {
      left: 1131px;
    }
    .frame3-image237 {
      left: 46px;
    }
    .frame3-text16 {
      top: 0px;
      left: 2%;
      right: 0px;
      margin-top: 27%;
    }
    .frame3-text17 {
      left: 2%;
      right: 0px;
      
    }
    .frame3-text18 {
      left: 2%;
      right: 0px;
    }
    .frame3-rectangle34624530 {
      left: 70%;
      width: 368px;
    }
    .frame3image5962710 {
      left: 76%;
    }
    .frame3-text19 {
      left: 70%;
    }
    .frame3-image239 {
      left: 46px;
    }
    .frame3-group1707485989 {
      left: 70%;
      width: 368px;
    }
    .frame3-rectangle34624531 {
      left: 70%;
      width: 368px;
    }
    .frame3-text25 {
      left: 71%;
    }
    .frame3-stressometer11 {
      left: 48px;
    }
    .frame3image2395 {
      left: 78%;
    }
  }
  
  @media only screen and (min-width: 1300px) and (max-width: 1388px) {
    .frame3-text11 {
      left: 1131px;
    }
    .frame3-image237 {
      left: 46px;
    }
    .frame3-text16 {
      top: 0px;
      left: 4%;
      right: 0px;
      margin-top: 27%;
    }
    .frame3-text17 {
      left: 4%;
      right: 0px;
      
    }
    .frame3-text18 {
      left: 4%;
      right: 0px;
    }
    .frame3-rectangle34624530 {
      left: 984px;
      width: 368px;
    }
    .frame3image5962710 {
      left: 1102px;
    }
    .frame3-text19 {
      left: 1002px;
    }
    .frame3-image239 {
      left: 46px;
    }
    .frame3-group1707485989 {
      left: 984px;
      width: 368px;
    }
    .frame3-rectangle34624531 {
      left: 984px;
      width: 368px;
    }
    .frame3-text25 {
      left: 1002px;
    }
    .frame3-stressometer11 {
      left: 48px;
    }
    .frame3image2395 {
      left: 1102px;
    }
  }
  