.faq-section {
    background: #e5e6c7;
}

.ingredient-section {
    background: #f4f2ea;
}
.mt-50 {
    margin-top: 50px;
}
.pb-60 {
    padding-bottom: 60px;
}
.pt-60 {
    padding-top: 60px;
}

.animated {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.Head-Faq{  color: var(--ltn__heading-color);
    clear: both;
    font-family: var(--ltn__heading-font);
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 15px;
}

.collapsed {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.ingredient-accordian .card {
    background: none;
    border-top: solid 1px #9c9d9d;
}
.benefit-accordian .card {
    border: none;
    border-top: solid 1px #e0a614;
    border-radius: 0px;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.benefit-accordian .card .ltn__card-title {
    padding: 18px 0;
    cursor: pointer;
    position: relative;
    transition: 0.3s;
    font-size: 1rem;
    margin-bottom: 0;
}

.benefit-accordian .card .ltn__card-title::before {
    position: absolute;
    content: "+";
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-family: "icomoon";
}
.benefit-accordian .card .ltn__card-title::before {
    color: var(--ltn__primary-color);
    font-size: 30px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
}

.benefit-accordian .card .card-body {
    padding: 0px;
    padding-bottom: 30px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.benefit-accordian .card .ltn__card-title[aria-expanded=true]:before {
    content: "-";
}