.error-container { 
    text-align: center; 
    background-color: #fff; 
    padding: 20px; 
    border-radius: 5px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} 
  
.error-container h1 { 
    font-size: 5rem; 
    color: #ff5733; 
} 
  
.error-container p { 
    font-size: 1.5rem; 
    color: #333; 
    margin-bottom: 20px; 
} 
  
.error-container a { 
    text-decoration: none; 
    background-color: #ff5733; 
    color: #fff; 
    padding: 10px 20px; 
    border-radius: 3px; 
    font-weight: bold; 
    transition: background-color 0.3s ease; 
} 
  
.error-container a:hover { 
    background-color: #e6482e; 
}