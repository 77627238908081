*{box-sizing: border-box;
margin: 0;
}


@media screen and (max-width: 768px) {
    .root-mobile{
        margin: 0 10px 0 10px;
    }
    }


    .ql-size-small {
        font-size: 12px;
      }
      
      .ql-size-medium {
        font-size: 18px;
      }
      
      .ql-size-large {
        font-size: 24px;
      }
      
      .ql-size-huge {
        font-size: 32px;
      }