.before{
    display: flex;
    padding: 2rem;
    justify-content: space-between;
}
.dem0-container{
    display: flex;
    gap: 2rem;
background-color: #E9EDEE;
padding: 2rem 3rem 2rem 3rem;
border-radius: 8px;
}
.dem0-container img{
    width: 100%;
}

@media (max-width: 768px) {
.dem0-container{
    flex-direction: column;
}
.before{
    flex-direction: column;
}
}
@media (max-width: 1080px) {
.dem0-container{
    padding: 2rem;
}
}

.after-before .slick-dots {
    bottom: -50px;
}

.slick-slide-dots-1 .slick-dots {
    position: absolute;
    bottom: 50px;
    width: 100%;
    margin: 0;
}
.slick-dots {
    margin: 0 0 30px;
    padding: 0;
    display: block;
    text-align: center;
    line-height: 1;
}

.slick-dots li:hover, .slick-dots li.slick-active {
    background-color: #fbd84e;
    width: 28px;
}

.slick-dots li {
    display: inline-block;
    list-style: none;
    display: inline-block;
    font-size: 0;
    height: 8px;
    width: 18px;
    border-radius: 10px;
    margin-right: 10px;
    background-color: #f1f1f1;
    cursor: pointer;
    margin-top: 0;
    transition: all 0.3s ease 0s;
}

.slick-dots li button {
    display: none;
}


/* .slick-slide {
    display: block;
    float: left;
    height: 100%;
    min-height: 1px;
} */