
  
  /* Header Section */
.page-6  .header {
    height: 111px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #54c0e8;
    color: white;
  }
  
.page-6  .header .logo img {
    height: 81px;
    width: 248.79px;
  }
  
.page-6  .header .life {
    height: 70px;
    width: 577px;
    background-color: #f9b473;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
  }
  
.page-6  .header h1 {
    font-size: 1.8rem;
    color: #292929;
  }
  
.page-6  .header p {
    font-size: 24px;
    color: #545454;
  }
  
.page-6  .smart-report {
    font-size: 1rem;
  }
  
  /* Do and Don't Section */
.page-6  .do-dont-section {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    padding: 0 20px;
    background-color: #fbe2cc;
    gap: 2%;
  }
  
.page-6  .card {
    background-color: white;
    border-radius: 28px;
    padding: 10px 10px 0 10px;
    width: 49%;
    max-width: 551px;
    /* box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1); */
    text-align: center;
    border: 1px solid black;
  }

  .page-6  h1 {
    margin: 20px 0 10 0 ;
  }
  
.page-6  .do-header,  .dont-header {
    display: flex;
    align-items: center;
  }
  
.page-6  .image {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.2);
    border: 4px solid #f7f7f7;
  }
  
.page-6  .do-header .image {
    background: linear-gradient(-90deg, #00de46 0%, #069471 100%);
  }
  
.page-6  .icon {
    height: 67px;
    width: 61px;
  }
  
.page-6  .dont-header .image {
    background: linear-gradient(90deg, #f2cc41 0%, #f72f19 57%);
  }
  
.page-6  .image-header {
    margin-left: 1rem;
    font-size: 4rem;
    font-weight: 900;
    color: #2e3192;
  }
  
.page-6  .illustration {
    width: 70px;
    height: 70px;
    margin: 0px auto;
  }
  
  ul {
    list-style-type: none;
    text-align: left;
  }
  
.page-6  .do-dont-section li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 17px;
  }
  
.page-6  .description {
    color: #494949;
    margin-left: 2rem;
    font-size: 8px;
  }
  
  strong {
    color: #494949;
    margin-right: 4px;
  }
  
.page-6  .right, .cross {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: absolute;
  }
  
.page-6  .right {
    background-color: #069471;
  }
  
.page-6  .right .right-icon {
    height: 20px;
    width: 25px;
  }
  
.page-6  .cross {
    background: linear-gradient(-90deg, #f2acd3 0%, #ff1c32 57%);
  }
  
.page-6  .advice-section {
    height: 1061px;
    width: 883px;
    background-color: white;
    margin: 100px auto;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
.page-6  .advice-section .graphic {
    height: 1062px;
    width: 883px;
    position: absolute;
  }
  
.page-6  .advice-section h2 {
    margin-top: 70px;
    font-family: poppins;
    font-size: 40px;
    color: #f16f56;
    margin-left: 40px;
    margin-right: 40px;
  }

  .page-6 ul {
    margin-bottom: 0;
  }
  
.page-6  .advice-section ul {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
  }
  
.page-6  .advice-section li {
    font-family: poppins;
    font-size: 20px;
    margin-bottom: 7px;
    color: #494949;
  }
  
.page-6  .main-content {
    margin-left: 80px;
    margin-inline: 20px;
  }
  
.page-6  .lightbulb {
    height: 459px;
    width: 332px;
    position: absolute;
    left: 67%;
    top: 289%;
  }

  .page-6  p {
    margin-bottom: 4px;
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
  .page-6  .header {
      flex-direction: column;
      height: auto;
      padding: 10px;
      text-align: center;
    }
  
  .page-6  .header .logo img {
      width: 150px;
      height: auto;
    }
  
  .page-6  .header .life {
      width: 90%;
      height: auto;
      padding: 10px;
      margin-top: 10px;
    }
  
  .page-6  .header h1 {
      font-size: 1.2rem;
      margin-top: 10px;
    }
  
  .page-6  .header p {
      font-size: 18px;
      margin-top: 5px;
    }
  
  /* .page-6  .do-dont-section { */
      /* flex-direction: column; */
      /* align-items: center; */
      /* margin-top: 50px; */
    /* } */
  
  .page-6  .card {
      width: 90%;
      /* margin-bottom: 30px; */
    }
  
  .page-6  .image-header {
      font-size: 2.5rem;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1200px) {
  .page-6  .header h1 {
      font-size: 1.6rem;
    }
  
  .page-6  .header .life {
      width: 70%;
    }
  
  .page-6  .image-header {
      font-size: 3rem;
    }
  }
  
.page-6  .bordered-container {
    background-color: white; /* White background for content */
    padding: 10px 40px 10px 40px;
    width:44%;
    background: url("../../../../../public/assets/img/reports/management/page6/pencil-border.png"); /* Path to pencil border image */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
    text-align: left;
    background-color: white;
    border-radius: 30px;
    background-size:contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    height: 440px;
  }

.page-6  .bordered-container h1 {
    font-size: 1rem;
    color: #ff5a3c; /* Color matching your header */
    text-align: center;
  }
.page-6  .bordered-container p {
    color: black;
    font-weight: bold;
    font-size: 10px;
  }
.page-6  .bordered-container li {
    font-size: 8px;
    color: #494949;
  }
.page-6  .helpful-tips-container {
    display: flex;
    justify-content: flex-end; /* Align image to the right */
    align-items: center;
    background-color: transparent; /* Optional: Transparent container background */
    padding: 0px 20px;
  }
  
.page-6  .helpful-tips {
    width: 80px; /* Resize image width */
    height: auto; /* Maintain aspect ratio */
  }

  .page-helpful-tips{
    position: absolute;
    bottom: -9%;
    right: 24%;

  }
  



.bordered-wrapper {
  margin-top: 0%;
}