@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  margin-top: 80px; /* Add a top margin to avoid content overlay */
}

.animate__fadeInRight,
.animate__fadeInLeft,
.animate__fadeIn,
.animate__flash,
.animate__fadeIn,
.animate__swing,
.animate__backInRight,
.animate__backInLeft,
.animate__fadeInUp,
.animate__zoomIn,
.animate__animated {
  animation-timeline: view();
  animation-range: entry 100px; /* animation-iteration-count: 1; */
}

.headShake_animation {
  animation: headShake 3s ease-in;
  animation-iteration-count: 3;
}
.left_animation {
  animation: backInLeft 2s ease-in;
}
.right_animation {
  animation: backInRight 2s ease-in;
}

.zoomIn_animation {
  animation: zoomIn 2s ease-in;
}
