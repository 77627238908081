.overlay {
  position: fixed;
  top: 0;
  z-index:1;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position:relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.resend-btn{
  border: none;
  text-decoration: underline;
  background: white;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
}

.sign-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sign-input {
  width: 100%;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-group {
  margin-bottom: 20px;
  position: relative; /* Add relative positioning */
}

.error {
  font-size: 14px;
  color: red;
  margin-top: 5px;
  position: absolute; /* Change to absolute positioning */
  bottom: -20px; /* Adjust as needed */
  left: 0;
}
.submit {
  width: 100%;
  height: 45px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.submit:hover {
  background-color: #0056b3;
}

.resend-message {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.social{
  margin-top: 30px;
  display: flex;
}
.social div{
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
}
.social div:hover{
  background-color: rgba(255,255,255,0.47);
}
.social .fb{
  margin-left: 25px;
}
.social i{
  margin-right: 4px;
}