* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624510 {
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 111px;
    background-color: #009fe3a4;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-hairsn11 {
    top: 0px;
    left: 18.0625px;
    width: 249px;
    height: 82px;
    position: relative;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624522 {
    background-color: white;
    width: 288px;
    height: 81px;
    margin-left: 4%;
    margin-top: 1%;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-group1707485987 {
    top: 21px;
    margin-left: -16em;
    width: 717px;
    height: 70px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
    background-color: rgba(158, 239, 246, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text121 {
    top: 2px;
    left: 0px;
    color: rgba(40, 40, 40, 1);
    right: 0px;
    height: auto;
    margin: auto;
    position: relative;
    font-size: 35px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .header-txt {
    margin-top: 2%;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text100 {
    margin-left: -10em;
    color: rgba(84, 84, 84, 1);
    height: auto;
    font-size: 24px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .guide-sec {
    width: 100%;
    height: auto;
  }
  .guide-box1 {
    background-color: #0996b4;
    display: flex;
    justify-content: space-between;
    height: 726px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-c7cc9b03af574a53b593ed98a32848b01 {
    margin-top: 9.6em;
    left: 32px;
    width: 382px;
    height: 382px;
    position: relative;
    border-radius: 11111px;
  }
  .guide-text {
    width: 80%;
    margin-top: 2%;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text117 {
    margin-top: 2%;
    left: 556px;
    color: rgba(255, 255, 255, 1);
    width: 804px;
    height: auto;
    position: relative;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224094 {
    gap: 10px;
    margin-top: 6em;
    margin-left: 21em;
    width: 480px;
    display: flex;
    padding: 10px 31px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text118 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .sub-content1 {
    margin-top: 2em;
    margin-left: 4em;
    width: 80%;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text101 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: relative;
    font-size: 20px;
    font-style: Bold;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    line-height: 189.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .guide-box2 {
    margin-top: 0.2%;
    background-color: #0996b4;
    display: flex;
    justify-content: space-between;
    height: 726px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-image4ef437afc3ff436497a997fe97bc675e1 {
    margin-top: 9.6em;
    left: 32px;
    width: 437px;
    height: 441px;
    position: relative;
    border-radius: 11111px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text122 {
    top: 1em;
    left: 9.9em;
    color: rgba(255, 255, 255, 1);
    width: 862px;
    height: auto;
    position: relative;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224095 {
    gap: 10px;
    margin-top: 3.8em;
    margin-left: 28em;
    width: 244px;
    display: flex;
    padding: 10px 31px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text119 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .sub-content3 {
    margin-top: 2em;
    margin-left: 6em;
    width: 80%;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text124 {
    top: 1em;
    left: 0;
    color: rgba(255, 255, 255, 1);
    width: 828px;
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 189.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .border-box1 {
    margin-left: 6.9em;
    width: 3px;
    height: 726px;
    background-color: rgba(219, 247, 255, 1);
  }
  .border-box {
    margin-left: 4em;
    width: 3px;
    height: 726px;
    background-color: rgba(219, 247, 255, 1);
  }
  .border-box2 {
    margin-left: 30.8em;
    width: 3px;
    height: 726px;
    background-color: rgba(219, 247, 255, 1);
  }
  .guide-box3 {
    margin-top: 0.2%;
    background-color: #0996b4;
    display: flex;
    justify-content: space-between;
    height: 726px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-e9144270c9ff437e90fdfe8eeec7a3be1 {
    margin-top: 9.6em;
    left: 32px;
    width: 429px;
    height: 428px;
    position: relative;
    border-radius: 111111px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text123 {
    top: 1em;
    left: 9.9em;
    color: rgba(255, 255, 255, 1);
    width: 721px;
    height: auto;
    position: relative;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224096 {
    gap: 10px;
    margin-top: 3.8em;
    margin-left: 24em;
    width: 365px;
    display: flex;
    padding: 10px 31px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text120 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text143 {
    top: 1em;
    left: -1em;
    color: rgba(255, 255, 255, 1);
    width: 828px;
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 189.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .last-sec {
    margin-top: 4em;
    margin-bottom: 5rem;
    margin-left: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .last-box1 {
    margin-left: 4em;
    border: 4px solid #0996b4;
    border-radius: 18px;
    width: 660px;
    height: 1005px;
    padding-right: 2.9em;
  }
  .last-flex1 {
    display: flex;
    justify-content: space-between;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-image036c30a210eb409fbc73cc0c7f063ed9 {
    top: 2em;
    left: 1.2em;
    width: 246px;
    height: 138px;
    position: relative;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text163 {
    top: 2em;
    color: rgba(55, 55, 55, 1);
    width: 368px;
    height: auto;
    position: relative;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224097 {
    gap: -12px;
    top: 3em;
    margin-left: -5em;
    width: 333px;
    display: flex;
    padding: 4px 17px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text183 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .lastsec-txt {
    margin-top: 1.9em;
    margin-left: 1.2em;
    width: 617px;
    height: 787px;
    border: 1px solid black;
    background-color: white;
    border-radius: 18px;
    padding-right: 3em;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text164 {
    top: 1em;
    left: 28px;
    color: rgba(73, 72, 72, 1);
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.8em;
    font-stretch: normal;
    text-decoration: none;
  }
  .last-border {
    top: 1.8em;
    left: 7.6em;
    width: 355px;
    height: 32px;
    position: relative;
    border-radius: 111111px;
    background-color: #0996b4;
  }
  .last-box2 {
    margin-left: 2em;
    border: 4px solid #0996b4;
    border-radius: 18px;
    width: 660px;
    height: 1005px;
    padding-right: 2.2em;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-af66c2fc84fb4baea3882934afc63609 {
    top: 2em;
    left: 1.2em;
    width: 208px;
    height: 157px;
    position: relative;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text181 {
    top: 2em;
    color: rgba(55, 55, 55, 1);
    width: 368px;
    height: auto;
    position: relative;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224098 {
    gap: -12px;
    top: 3em;
    margin-left: -5em;
    width: 333px;
    display: flex;
    padding: 4px 17px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text183 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .lastsec-txt2 {
    margin-top: 1.6em;
    margin-left: 1.2em;
    width: 617px;
    height: 787px;
    border: 1px solid black;
    background-color: white;
    border-radius: 18px;
    padding-right: 3em;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text187 {
    top: 1em;
    left: 28px;
    color: rgba(73, 72, 72, 1);
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.8em;
    font-stretch: normal;
    text-decoration: none;
  }
  .last-border2 {
    top: 1.1em;
    left: 7.6em;
    width: 355px;
    height: 32px;
    position: relative;
    border-radius: 111111px;
    background-color: #0996b4;
  }
  .last-box3 {
    margin-top: 2em;
    margin-left: 4em;
    border: 4px solid #0996b4;
    border-radius: 18px;
    width: 660px;
    height: 1005px;
    padding-right: 2.9em;
  }
  .last-flex2 {
    display: flex;
    justify-content: space-between;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-a75c4e3480d5497584fceb35138967251 {
    top: 2em;
    left: 1.2em;
    width: 280px;
    height: 158px;
    position: relative;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text208 {
    top: 2em;
    color: rgba(55, 55, 55, 1);
    width: 368px;
    height: auto;
    position: relative;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224101 {
    gap: -12px;
    top: 3em;
    margin-left: -5em;
    display: flex;
    padding: 4px 17px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .lastsec-txt3 {
    margin-top: 1.9em;
    margin-left: 1.2em;
    width: 617px;
    height: 787px;
    border: 1px solid black;
    background-color: white;
    border-radius: 18px;
    padding-right: 3em;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text209 {
    top: 1em;
    left: 28px;
    color: rgba(73, 72, 72, 1);
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.8em;
    font-stretch: normal;
    text-decoration: none;
  }
  .last-border3 {
    top: 0.6em;
    left: 7.6em;
    width: 355px;
    height: 32px;
    position: relative;
    border-radius: 111111px;
    background-color: #0996b4;
  }
  .last-box4 {
    margin-top: 2em;
    margin-left: 2em;
    border: 4px solid #0996b4;
    border-radius: 18px;
    width: 660px;
    height: 1005px;
    padding-right: 2.9em;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-d380146ee98e4ce28d371ad733ff3dc7 {
    top: 2em;
    left: 1.2em;
    width: 276px;
    height: 155px;
    position: relative;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text182 {
    top: 2em;
    color: rgba(55, 55, 55, 1);
    width: 368px;
    height: auto;
    position: relative;
    font-size: 24px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-frame2147224100 {
    gap: -12px;
    top: 3em;
    margin-left: -5em;
    width: 333px;
    display: flex;
    padding: 4px 17px;
    position: relative;
    align-items: center;
    border-radius: 11111px;
    justify-content: center;
    background-color: rgba(10, 120, 150, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text185 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text229 {
    top: 1em;
    left: 28px;
    color: rgba(73, 72, 72, 1);
    height: auto;
    position: relative;
    font-size: 18px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.8em;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .last-border4 {
    top: 0.6em;
    left: 7.6em;
    width: 355px;
    height: 32px;
    position: relative;
    border-radius: 111111px;
    background-color: #0996b4;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle346245462 {
    top: 700px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 726px;
    margin: auto;
    position: relative;
    border-color: rgba(255, 255, 255, 1);
  }
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624686 {
    top: 1430px;
    right: 0px;
    width: 100%;
    height: 726px;
    position: relative;
    border-color: rgba(255, 255, 255, 1);
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624541 {
    top: 111px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 585px;
    margin: auto;
    position: relative;
    border-color: rgba(255, 255, 255, 1);
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text102 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text103 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text105 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text106 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text108 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text109 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text111 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text112 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text114 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text117 {
    top: 138px;
    left: 556px;
    color: rgba(255, 255, 255, 1);
    width: 804px;
    height: auto;
    position: absolute;
    font-size: 36px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-group1707485983 {
    top: 15px;
    left: 63px;
    width: 286px;
    height: 81.8226547241211px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text125 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text128 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text129 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text131 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text132 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text134 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text135 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text137 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text138 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text140 {
    font-weight: 700;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text144 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text146 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text147 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text149 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text150 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text152 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text153 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text155 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text157 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text158 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text160 {
    font-weight: 700;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text165 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text167 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text168 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text170 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text171 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text173 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text174 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text176 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text177 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text179 {
    font-weight: 700;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624697 {
    top: 4454px;
    left: 207px;
    width: 355px;
    height: 32px;
    position: absolute;
    border-radius: 111111px;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624547 {
    top: 2274px;
    left: 726px;
    width: 660px;
    height: 1005px;
    position: absolute;
    border-color: rgba(9, 150, 180, 1);
    border-style: solid;
    border-width: 5px;
    border-radius: 15px;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624549 {
    top: 3263px;
    left: 876px;
    width: 355px;
    height: 32px;
    position: absolute;
    border-radius: 111111px;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text184 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text186 {
    color: rgba(255, 255, 255, 1);
    width: 320px;
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-rectangle34624700 {
    top: 3525px;
    left: 745px;
    width: 617px;
    height: 882px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 22px;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text188 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text190 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text191 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text193 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text194 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text196 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text197 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text199 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text200 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text202 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text203 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text205 {
    font-weight: 700;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text210 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text212 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text213 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text215 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text216 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text218 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text219 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text221 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text222 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text224 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text225 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text227 {
    font-weight: 800;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-text230 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text232 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text233 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text235 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text236 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text238 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text239 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text241 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text242 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text244 {
    font-weight: 700;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text245 {
    font-weight: 400;
  }
  .management-report-page3-hair-scalp-treatment-recommendat-text247 {
    font-weight: 700;
  }
  
  .management-report-page3-hair-scalp-treatment-recommendat-image4ef437afc3ff436497a997fe97bc675e1 {
    /* top: 847px;
    left: 14px; */
    width: 437px;
    height: 441px;
    position: absolute;
    border-radius: 11111111px;
  }
  
  @media (max-width: 1386px) {
    .guide-text {
      width: 80%;
      margin-top: -2%;
    }
    .management-report-page3-hair-scalp-treatment-recommendat-text117 {
      margin-left: -1em;
      margin-top: -0.4em;
    }
    .sub-content1 {
      margin-top: 0.6em;
    }
    .last-sec {
      width: 50%;
    }
    .last-box1 {
      margin-left: 0.4em;
    }
    .last-box2 {
      margin-left: 0.4em;
    }
    .last-box3 {
      margin-left: 0.4em;
    }
    .last-box4 {
      margin-left: 0.4em;
    }
  }
  

  .text-guide{
    font-size: 34px;
    text-align: center;
    font-weight: 700;
    color: white;
  }