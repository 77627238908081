/* Footer.css */
.footer-container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    gap: 1.5rem;
    padding-top: 4rem;
   /* Adjust gap as needed */
  }
  
  .footer-item {
    /* Just for visualization */
 
     /* Just for visualization */
  }
  .footer-item img{
    width: 50%;
    margin-bottom: 2rem;
  }

  .footer-item svg{
    display: inline;
    margin-right: 1rem;
  }
  .icon-footer{
    display: flex;
    margin-bottom: 2rem;
  }
  .al{
    align-items: center;
  }
  .footer-item h3{
    margin-bottom: 2rem;
  }
.com-con{
    margin-bottom: 1.4rem;
}
.footer-item input{
    height: 3rem;
    width: 20rem;
    font-size: 1.2rem;
}
.footer-item button{
    padding: 0.74rem;
    /* border-left: none; */
    border: 1px solid black;
    border-left: none;
    background-color: #A2A2A2;
}
.footer-item input{
    height: 3rem;
    width: 20rem;
    font-size: 1.2rem;
    border: 1px solid black;
    border-right: none;
}
.footer-item form{
    display: flex;
}

@media (max-width: 768px) {
.footer-container{
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
}
.footer-item button{
  padding: 0.72rem;
}
}

.copyright{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  background-color: #6ED6F4 !important;
  align-items: center;
  
}