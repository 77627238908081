.faq-container {
    
    display: flex;
    flex-direction: column;
  
  padding-bottom: 6rem;
  }
  
  .faq-item {
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: 500;
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .faq-item:hover {
   cursor: pointer;
  }
  @media only screen and (max-width: 425px) {
    .faq-container {
      margin: 1rem;
    }
  }
  