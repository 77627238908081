* {
  font-family: Nunito, sans-serif;
}

.text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  line-height: 25px;
}

.responsive-cell-block {
  min-height: 75px;
}

.responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.outer-container {
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
}

.inner-container {
  max-width: 1320px;
  margin-top: 50px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
}

.heading-text {
  font-size: 48px;
  line-height: 65px;
  font-weight: 900;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.sub-heading-text {
  color: rgb(122, 122, 122);
  line-height: 30px;
}

.team-1-swiper {
  overflow: hidden;
}

.team-2-swiper {
  overflow: hidden;
}

.team-name {
  font-size: 24px;
  line-height: 34px;
  font-weight: 900;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.team-member-image {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}



.arrow-left-1 {
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  cursor: pointer;
}

.swiper-slide {
  margin-top: 0px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.arrow-left-2 {
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  cursor: pointer;
}

.arrow-right-1 {
  height: 14px;
  cursor: pointer;
}

.swiper-slide.empty {
  width: auto;
  margin-top: 0px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: -20px;
}

.swiper-container.team-1-swiper,
.swiper-container.team-2-swiper {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.arrow-right-2 {
  cursor: pointer;
}

@media (max-width: 768px) {
  .heading-text {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .sub-heading-text {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {


  .outer-container {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
}