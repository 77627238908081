.product-section{
    display: flex;
    gap: 2rem;
    border-bottom: 1px solid gray;
    padding-bottom: 3rem;
    
}
.product-image{
    text-align: center;
    flex:1
    
}
.product-detail{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.quantity-input{
    
    display: flex;
    gap: 1rem;
    align-items: center;
}
.quantity-input input{
    border: 1px solid #a09e9e;
    height: 2rem;
    width: 2rem;
}
.cout-cont{
    display: flex;
}
.count-item{
    display: flex;
    flex-direction: column;
}
.count-item button{
    border: none;
    background-color: white;
}
.shop-btn1{
    width: 10rem;
    height: 2.5rem;
    margin-right: 1rem;
    border:none;
    background-color: #ffa500;
    color: white;
    margin-top: 1rem;
    border-radius: 5px;
}
.btn-33{
   background-color: #329fca  !important;
}
.detail-cont{
    margin-top: 2rem;
}
.detail-cont h1{
    margin-bottom: 2rem;
}

.hilight {
    color: var(--gray-3);
    font-weight: bold;
    padding-bottom: 10px;
}

.hilight2 {
    color: var(--gray-3);
    font-size: 12px;
    color: var(--gray-1);
    line-height: 24px;
    margin: 0px;
}

.ingredient-section {
    background: #f4f2ea;
}

.pb-60 {
    padding-bottom: 60px;
}

.pt-60 {
    padding-top: 60px;
}

.ltn__shop-details-tab-content-inner {
    padding: 0px 0px 0px;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.ltn__shop-details-tab-content-inner h3 .write {
    background: #2c2b2b;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    border-radius: 5px;
    color: white;
    text-decoration: auto;
}

.ltn__shop-details-tab-content-inner h3 a {
    float: right;
}

.ltn__comment-inner ul {
    margin: 0;
    padding: 0;
}

.ltn__comment-item {
    position: relative;
}

.ltn__commenter-img {
    float: left;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    background: #e9e9e9;
    border-radius: 100px;
    color: var(--black);
    text-align: center;
    line-height: 60px;
}

.ltn__commenter-comment {
    overflow: hidden;
}

.product-ratting {
    margin-bottom: 5px;
}

.fa, .fas {
    font-weight: 900;
}

.fa, .far, .fas {
    font-family: "Font Awesome 5 Free";
}
.fa, .fab, .fad, .fal, .far, .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.ltn__shop-details-tab-content-inner {
    padding: 0px 0px 0px;
}

.horizontal-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .ltn__comment-inner li p {
    font-size: 14px;
    color: var(--black);
    padding-top: 10px;
}

.ltn__comment-inner li {
    list-style: none;
    /* padding-top: 30px; */
    margin-top: 8px;
}

.ltn-main{
    border-top: 1px solid #d1dae0;
    padding-top: 30px;
}


.slick-initialized .slick-slide {
    display: block;
}

.story-slider {
    background: #efeec8;
    border-radius: 8px;
    padding: 40px 40px 40px 50px;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.buy-btn {
    border-radius: 5px;
    background: var(--orange);
    color: var(--white);
    padding-left: 15px;
}

.star-review{
    font-size: 25px;
    color: #ffa500;
}

.star-review-inactive{
    font-size: 25px;
    color: #ffa500;
}

.barr2{
    background-color: #FBD84E;
    margin-bottom: 4rem;
  }

  .coverflow-item-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    position: absolute;
    backface-visibility: visible;
    top: 0;
    left: 0;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: #111;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.5s all ease-out;
    transition: 1.25s all cubic-bezier(.17,.75,.58,.95);
    box-shadow: 0px 10px 27px -1px rgb(0 0 0 / 70%);
}

.story-slider h2 {
    padding-bottom: 40px;
    position: relative;
    z-index: 9;
}

.story-slider h4 {
    font-size: 1.375rem;
    font-weight: normal;
    margin-bottom: 10px;
}

.story-slider p {
    font-size: 1rem;
    color: var(--black);
}

.happy-customer{
    width: 1220px;
    height:400px
}

.cust-video{
    width:500px
  }
  .zoom-container {
    position: absolute;
    top: 0;
    left: 320px; /* Position the zoomed image 20px beside the main image */
    border: 1px solid #ccc;
    background: white;
    z-index: 1000;
  }
  
  .zoomed-image {
    width: 600px; /* Zoomed-in size */
    height: auto;
  }
  

  
@media screen and (max-width: 768px) {

    .barr2{
        margin-bottom: 2rem;
      }


    .happy-customer{
        width: 100%;
        height:600px
    }
    .cust-video{
        width:100%
      }
      .zoom-container {
        display: none; /* Hide zoom on small screens */
      }
  }

  .img-magnify{
    max-width: 100%;
    height: 100% !important;
  }





.plus-sign{
    border: 1px solid #a09e9e;
   
    width: 2rem;
    /* padding: 5px 0 0 0; */
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.negative-sign{
    border: 1px solid #a09e9e;
   
    width: 2rem;
    /* padding: 5px 0 0 0; */
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

/* .quantity-input input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    opacity: 1;
} */

.buyNow-main-div{
    position: fixed;
    /* right: 0; */
    /* top: 0; */
    bottom: 0;
    z-index: 1000;
    text-align: center;
    width: 100%;
    /* border: 1px solid; */
    background: #FFFFFF;
    box-shadow: 0 0 9px rgb(0 0 0 / 12%);
}

.buyNow-product-name{
    display: flex;
    align-items: center;
    font-size: 20px;
}