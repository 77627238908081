.cart {
    font-family: Arial, sans-serif;
    /* padding: 20px; */
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cart h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .cart-items {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    flex-direction: row;
  }
  
  .cart-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .cart-item-details {
    flex-grow: 1;
  }
  
  .cart-item-details h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
  }
  
  .cart-item-details p {
    margin: 5px 0;
  }
  
  .cart-item-actions {
    display: flex;
    align-items: center;
  }
  
  .cart-item-actions input {
    width: 50px;
    text-align: center;
    margin-right: 10px;
  }
  
  .cart-item-actions button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cart-item-actions button.remove {
    background-color: red;
    margin-left: 10px;
  }
  
  .cart-summary {
    text-align: right;
    margin-top: 20px;
  }
  
  .cart-summary p {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .cart-summary button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  


  @media screen and (min-width:280px){
    .cart-item{
      flex-direction: column;
    }
  }

  @media screen and (min-width: 420px){
    .cart-item{
      flex-direction: row;
    }
  }
  
  